<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 流程 -->
      <ServiceProcess :info="info" v-ani.fade-up />
      <!-- 提供服务 -->
      <ServiceList title='提供服务' :list='list' />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";

export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      // 轮播图列表
      bannerList: [
        // {
        //   id: 1,
        //   link: '',
        //   btn: false,
        //   target: '',
        //   img: require('@/assets/images/svc3_0.jpg'),
        //   title: '工程预决算',
        //   intro: '标准化施工，精细化管理'
        // },
      ],
      info: {
        title: '工程预决算',
        desc: '对工程项目进行预算和决算的服务，包括编制工程量清单、进行工程量统计、审核工程款申请、负责工程承包商和材料供应商的结算工作等。提高工程项目的管理水平和效率，确保项目按照预算和计划进行，同时对项目成本进行控制和监督。',
        list: [
          { id: 1, title: '确定项目<br>需求' },
          { id: 2, title: '收集资料' },
          { id: 3, title: '进行工程<br>测量' },
          { id: 4, title: '制定工程<br>概算' },
          { id: 5, title: '进行成本<br>控制' },
          { id: 6, title: '定期更新<br>预算' },
        ]
      },
      list: [
        {
          id: 1,
          img: require('@/assets/images/svc3_1.jpg'),
          tit: '增强透明度',
          desc: '通过工程预决算服务，企业可以更好地进行项目管理和协调，提高工作效率和协同性，从而提高企业的竞争力和市场占有率。'
        }, {
          id: 2,
          img: require('@/assets/images/svc3_2.jpg'),
          tit: '提高预算精度',
          desc: '通过工程预决算服务，企业可以制定出更加准确和可靠的预算方案，从而提高预算精度和准确性。'
        }, {
          id: 3,
          img: require('@/assets/images/svc3_3.jpg'),
          tit: '提高竞争力',
          desc: '通过工程预决算服务，企业可以更好地进行项目管理和协调，提高工作效率和协同性，从而提高企业的竞争力和市场占有率。'
        }, {
          id: 4,
          img: require('@/assets/images/svc3_4.jpg'),
          tit: '规避风险',
          desc: '通过工程预决算服务，企业可以制定出更加准确和可靠的预算方案，从而提高预算精度和准确性。'
        }, {
          id: 5,
          img: require('@/assets/images/svc3_5.jpg'),
          tit: '提高竞争力',
          desc: '工程预决算服务可以帮助企业遵守相关法规和规定，规避风险并确保项目的合法性和合规性。'
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped></style>